export default {
    INFINITE_DATE: new Date( '9999-01-01' ),
    FIRST_DATE: new Date( '1901-01-01' ),
    FORMAT: {
        DAY_OF_WEEK: 'eee',
        DAY: 'd',
        MONTH: 'MMM',
        UI_DATE: 'MMM dd yyyy',
        UI_DATE_TIME: 'MMM dd yyyy p',
        UI_DATE_DAY_TIME: 'eee, MMM d • p',
        API_DATE: 'yyyy-MM-dd',
        DEFAULT: 'yyyy-MM-dd',
        TIME: 'p',
        TIME_HOUR_MINS: 'HH:mm',
        FULL: 'eee, PP',
        PERIOD: 'eee, MMM d',
        PERIOD_FULL: 'eee, MMM d, yyyy',
        PERIOD_FULL_DOT_SEPARATED: 'eee • MMM d, yyyy',
        LONG_LOCALIZED_DATE: 'PP'
    }
};
