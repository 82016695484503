<template>
    <v-btn
        :class="['gst-btn', invert && 'gst-btn--invert', disabled && 'gst-btn--disabled', active && 'gst-btn--active', classes]"
        depressed
        :loading="!!loading"
        :disabled="disabled"
        v-bind="$attrs"
        :data-test-id="dataTestId"
        @click="$emit('click', $event)">
        <slot v-if="!placeholder"></slot>
        {{ placeholder }}
    </v-btn>
</template>
<script>
    export default {
        name: 'BaseButton',
        inheritAttrs: true,
        props: {
            placeholder: {
                type: String,
                default: ''
            },
            dataTestId: {
                type: String,
                default: ''
            },
            invert: {
                type: Boolean,
                default: false
            },
            disabled: {
                type: Boolean,
                default: false
            },
            classes: {
                type: String,
                default: () => ''
            },
            loading: {
                type: [ Boolean, Number ],
                default: false
            },
            active: {
                type: Boolean,
                default: false
            }
        },
    };
</script>
