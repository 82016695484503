<template>
    <div v-click-outside="onClickOutsideDo" class="gst-vc-date-picker-container pt-4">
        <DataLoadingOverlay
            :show="loading"
            :color="'white'"
            :opacity="'0.8'">
            <div class="d-flex flex-start pl-4">
                <BaseButton
                    key="allDates" 
                    invert
                    small
                    :classes="'gst-all-dates text-capitalize pa-2 mr-2'"
                    :active="activeButton === 'ALL_DATES'"
                    :placeholder="$t('labels.allDates')"
                    @click="selectAllDates('ALL_DATES')" />
                <BaseButton
                    key="thisWeekend" 
                    invert
                    small
                    :classes="'gst-this-weekend text-capitalize pa-2'"
                    :active="activeButton === 'THIS_WEEKEND'"
                    :placeholder="$t('labels.weekend')"
                    @click="selectThisWeekend('THIS_WEEKEND')" />
            </div>
            <div class="gst-separator my-4"></div>
            <vc-date-picker
                ref="vcDatePicker"
                v-model="selectedDates"
                class="gst-vc-date-picker"
                is-expanded
                is-range
                mode="date"
                :step="1"
                :attributes="attributes"
                :columns="2"
                :locale="language"
                :min-date="minDate || new Date()"
                :first-day-of-week="1"
            />
            <div class="d-flex justify-space-between align-center pt-0 pa-4">
                <v-btn
                    text
                    small
                    :disabled="!resetButtonEnabled"
                    class="text-capitalize gst-btn--reset px-0"
                    :color="color"
                    @click="onReset">
                    {{ $t('labels.reset') }}
                </v-btn>
                <BaseButton
                    small
                    :disabled="!confirmButtonEnabled"
                    :active="confirmButtonEnabled"
                    :classes="'text-capitalize gst-btn--confirm pa-2'"
                    :placeholder="$t('labels.confirm')"
                    @click="onConfirm" />
            </div>
        </DataLoadingOverlay>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import endOfWeek from 'date-fns/endOfWeek';
import isSunday from 'date-fns/isSunday';
import dateConstants from '@core/utils/constants/date';
import BaseButton from '@core/shared/components/buttons/BaseButton.vue';
import DataLoadingOverlay from '@core/shared/components/loading/DataLoadingOverlay.vue';
import {
        parseString as dateUtilsParseString,
    } from '@core/utils/dateUtils';


export default {
    name: 'BaseVCDatePicker',
    components: {
        BaseButton,
        DataLoadingOverlay,
    },
    props: {
        color: {
            type: String,
            default: 'primary'
        },
        eventDates: {
            type: Array,
            default: () => []
        },
        isConfirmed: {
            type: Boolean,
            default: false
        },
        value: {
            type: Object,
            default: ( ) => ( { startDate: null, endDate: null } )
        },
        minDate: {
            type: Date,
            default: undefined
        },
        modal: {
            type: Boolean,
            default: true
        },
        hasPopover: {
            type:Boolean,
            default: false,
        },  
        loading: {
            type: Boolean,
            default: false
        },
        onConfirmDo: {
            type: Function,
            default: () => {}
        },
        allowSameDayRange: {
            type: Boolean,
            default: true
        }
    },
    data( ) {
        return {
            selectedDates: {
                start: null,
                end: null,
            },
            lastAppliedDates: {
                start: null,
                end: null,
            },
            weekendDates: {
                start: null,
                end: null,
            },
            activeButton: null,
        };
    },
    computed: {
        ...mapState( {
            language: state => state.appState.language
        } ),
        attributes() {
            return [
                {
                    key: 'today',
                    highlight: {
                        start: {
                            fillMode: 'none',
                            contentStyle: {
                                borderStyle: 'solid',
                                borderWidth: '1px',
                                borderColor: this.$vuetify.theme.themes.light.primary,
                                backgroundColor: 'white',
                                color: this.$vuetify.theme.themes.light.quaternary
                            },
                            class: 'gst-min-date'
                        },
                    },
                    dates: this.minDate || new Date(),
                },
                {
                    dot: {
                        class:'gst-vc-date-picker__dot--existing-event'
                    },
                    dates: this.eventDates,
                }
            ]
        },
        resetButtonEnabled() {
            return this.isConfirmed || this.selectedDates.start !== null || this.lastAppliedDates.start !== null;
        },
        confirmButtonEnabled() {
            return this.selectedDates.start !== null || this.lastAppliedDates.start !== null;
        },
        isSameDayRange( ) {
            const { start, end } = this.selectedDates;
            return new Date(start).getTime() === new Date(end).getTime();
        },
        isThisWeekend( ) {
            return this.weekendDates.end === this.selectedDates.end;
        },
        datesOutput() {
            return {
                startDate: this.selectedDates.start ? dateUtilsParseString( this.selectedDates.start ): null,
                endDate: this.isSameDayRange && !this.allowSameDayRange ? null : dateUtilsParseString( this.selectedDates.end )
            };
        },
    },
    watch: {
        'value': function ( ) {
            this.setDatesFromPropValue();
        },
        selectedDates: {
            handler(value){
                if ( this.isSameDayRange && !this.allowSameDayRange ) {
                    this.selectedDates.start = value.start;
                    this.selectedDates.end = value.end;
                }
                if( this.activeButton ) {
                    if (this.activeButton === 'ALL_DATES' && this.confirmButtonEnabled) this.activeButton = null;  
                    if (this.activeButton === 'THIS_WEEKEND' && !this.isThisWeekend) this.activeButton = null;
                }
            },
            deep: true
        }
    },
    methods: {
        selectAllDates(button) {
            this.selectedDates = { start: null, end: null };
            this.activeButton = button;
            this.debounceOnConfirm();
        },
        selectThisWeekend(button) {
            const start = !isSunday( new Date() ) ? this.$options.filters.date( endOfWeek( new Date(), { weekStartsOn: 0 } ), dateConstants.FORMAT.API_DATE ) : null;
            const end = this.$options.filters.date( endOfWeek( new Date(), { weekStartsOn: 1 } ), dateConstants.FORMAT.API_DATE );
            this.selectedDates = { start, end };
            this.weekendDates = { start, end };
            this.activeButton = button;
            this.debounceOnConfirm();
        },
        onReset( ) {
            this.selectedDates = { start: null, end: null };
            this.lastAppliedDates = { start: null, end: null };
            this.weekendDates = { start: null, end: null};
            this.activeButton = null;
        },
        onConfirm( ) {
            if ( !this.modal ) {
                this.$emit( 'confirm', this.datesOutput );
            }
            else {
                this.onConfirmDo( this.datesOutput );
                this.$emit( 'close' );
            }
            this.lastAppliedDates = this.selectedDates;
        },
        onClickOutsideDo( ) {  
            this.$emit( 'click-outside', this.datesOutput);
        },
        setDatesFromPropValue( ) {
            const newValue = this.value;
            this.selectedDates.start = this.$options.filters.date( newValue.startDate, dateConstants.FORMAT.API_DATE ) || null,
            this.selectedDates.end = this.$options.filters.date( newValue.endDate, dateConstants.FORMAT.API_DATE ) || null
            
            this.lastAppliedDates.start = this.selectedDates.start;
            this.lastAppliedDates.end = this.selectedDates.end;
            this.selectedDates = { ...this.selectedDates };
        },
        debounceOnConfirm() {
            setTimeout( () => {
                this.onConfirm();
            }, 100 );
        },
    },
    created( ) {
        this.setDatesFromPropValue();
    }
}
</script>

<style lang="scss">
@import "@scssVariables";

.gst-vc-date-picker__dot--existing-event {
    position: absolute;
    top: theme-spacing(1);
    left: theme-spacing(1);
    height: theme-spacing(2) !important;
    width: theme-spacing(2) !important;
    border: 1px solid theme-color('white');
    background-color: theme-color('primary') !important;
}

.vc-container {
    --blue-100: var(--v-primary-base); 
    --blue-200: var(--v-primary-base);  
    --blue-300: var(--v-primary-base); 
    --blue-400: var(--v-primary-base); 
    --blue-500: var(--v-primary-base); 
    --blue-600: var(--v-primary-base);
    --blue-700: var(--v-primary-base); 
    --blue-800: var(--v-primary-base); 
    --blue-900: white;
    --gray-500: var(--v-tertiary-base);
    --gray-800: var(--v-tertiary-base);
    --gray-900: var(--v-quaternary-base);
    --text-sm: 12px;
}

.gst-vc-date-picker.vc-container {
    width: 100%;
    border: none !important;
    font-family: $font-family;
    
    .vc-arrows-container .vc-svg-icon path {
        fill: var(--v-tertiary-base);;
    }
}

.gst-vc-date-picker-container .gst-separator {    
    height: 1px;
    background: theme-color('tertiary');
    opacity: 0.2;
}

.gst-vc-date-picker .vc-title {
    padding-bottom: 15px;
    font-size: var(--text-base);
    letter-spacing: -0.471px;
}

.gst-vc-date-picker .vc-day-content:focus, .gst-vc-date-picker .vc-day-content:hover {
    background-color: var(--v-primary-base);
    color: theme-color('white');
}

.gst-vc-date-picker .vc-highlight.vc-highlight-base-middle {
    width: unset !important;
}

.gst-vc-date-picker .vc-day:not(.gst-min-date) .vc-highlights.vc-day-layer + span.vc-day-content  {
    background-color: var(--v-primary-base);
}

.gst-vc-date-picker .gst-min-date .vc-highlights.vc-day-layer {
    .vc-highlight:not(.vc-highlight-base-start) {
        height: 29px;
        width: 29px;
        border: 1px solid var(--v-primary-base) !important;
    }
}

.gst-vc-date-picker .vc-day {
    padding: theme-spacing(1);

    .vc-highlight.vc-highlight-base-start, .vc-highlight.vc-highlight-base-end {
        width: unset !important;
        border-radius: none !important;
        border-right-width: none !important;      
    }
}

.gst-all-dates.gst-btn.v-btn, .gst-this-weekend.gst-btn.v-btn, .gst-btn--confirm.gst-btn.v-btn {
    padding: theme-spacing( 3 , 4 ) !important;
    letter-spacing: -0.25px;
}

.gst-btn--confirm.gst-btn.v-btn {
    font-size: font-size('s')!important;
}
    
</style>